import DateRangeIcon from '@mui/icons-material/DateRange';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import { Box, IconButton, Popover } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../corelogic/redux/create-store';
import { DailyCapacityFileInputType } from '../../../../../../../corelogic/redux/dailyCapacityFile/dailyCapacityFile.reducer';
import { setRetrieveDailyCapacityFile } from '../../../../../../../corelogic/redux/dailyCapacityFile/dailyCapacityFile.slice';
import { getFormattedDateWithMinute } from '../../../../../../../helpers/dateFunction';
import { StripedDataGrid } from '../../../../../../../util/functionUtil';
import { COLUMN_LABEL_DAILY_CAPACITY } from '../../../type';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { dateTimeColumnType } from './component/EditDateInput/editDateInput';
import styles from './dailyCapacityTable.module.scss';
import { Button } from '@mui/material';
import { setUpdateSiteDailyCapacity } from '../../../../../../../corelogic/redux/site/site.slice';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const XLSX = require('xlsx');

const DailyCapacityTable: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { dailyCapacities } = useSelector((state: RootState) => state.dailyCapacities);
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  //This is a string instead of a date because we need to use it inside a date picker as a controlled value however we
  //cannot the a default value here otherwise the filter will trigger and if we use undefined the component will switch
  //from controlled to uncontrolled uring runtime, not good
  const [selectedDate, setSelectedDate] = useState<string | undefined>('');
  const [fileName, setFileName] = useState<string>();
  const [creationDate, setCreationDate] = useState<string>();
  const [modifiedDailyCapacity, setModifiedDailyCapacity] = useState<any[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const siteId = localStorage.getItem('favouriteSite');

  function handleDownloadClick(creationDate: string, siteId: string, type: string) {
    if (creationDate == undefined || siteId == undefined) {
      return;
    }
    const queryInput: DailyCapacityFileInputType = {
      creationDate: creationDate,
      siteId: siteId,
      type: type,
    };
    dispatch(setRetrieveDailyCapacityFile(queryInput));
  }

  useEffect(() => {
    if (fileName && file) {
      const fileReader = new FileReader();

      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target !== null) {
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const firstSheetName: string = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const parsedData = XLSX.utils.sheet_to_json(worksheet);
          setModifiedDailyCapacity(parsedData);
        }
      };

      fileReader.readAsBinaryString(file);
    }
  }, [fileName, file]);

  useEffect(() => {
    if (modifiedDailyCapacity.length > 0 && siteId != null && creationDate != null) {
      const jsonString = JSON.stringify(modifiedDailyCapacity);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const formData = new FormData();
      formData.append('file', blob);
      dispatch(setUpdateSiteDailyCapacity({ siteId, modifiedDailyCapacity: formData, creationDate }));
    }
  }, [modifiedDailyCapacity]);

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>, row: any) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFileName(selectedFile?.name);
      setCreationDate(row.creation_date);
      setFile(selectedFile);
    }
  };

  const columns: GridColDef[] = [
    {
      field: COLUMN_LABEL_DAILY_CAPACITY.FILE_SITE_NAME,
      headerName: intl.formatMessage({ id: 'dailyCapacity.name' }),
      disableColumnMenu: true,
      filterable: false,
      flex: 2,
    },
    {
      field: COLUMN_LABEL_DAILY_CAPACITY.CREATION_DATE,
      ...dateTimeColumnType,
      headerName: intl.formatMessage({ id: 'dailyCapacity.creationDate' }),
      editable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      renderCell: params => {
        const date = new Date(params.value);
        return getFormattedDateWithMinute(date);
      },

      renderHeader: () => (
        <Fragment>
          <div style={{ fontWeight: 500 }}>
            {intl.formatMessage({ id: 'dailyCapacity.creationDate' })}
          </div>
          <IconButton
            aria-describedby={id}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setDatePickerOpen(!datePickerOpen);
              setAnchorEl(event.currentTarget);
            }}
          >
            <DateRangeIcon />
          </IconButton>
        </Fragment>
      ),
    },
    {
      field: COLUMN_LABEL_DAILY_CAPACITY.DOWNLOAD,
      headerName: intl.formatMessage({ id: 'dailyCapacity.download' }),
      align: 'center',
      headerAlign: 'center',
      description: intl.formatMessage({ id: 'dailyCapacity.downloadLink' }),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: param => (
        <IconButton
          color="actionButton"
          onClick={() =>
            handleDownloadClick(param.row.creation_date, param.row.site_id, param.row.type)
          }
          className={styles.download_button_style}
        >
          <GetAppOutlinedIcon />
        </IconButton>
      ),
    },
    {
      field: COLUMN_LABEL_DAILY_CAPACITY.UPLOAD,
      headerName: intl.formatMessage({ id: 'dailyCapacity.upload' }),
      editable: false,
      align: 'center',
      headerAlign: 'center',
      description: intl.formatMessage({ id: 'dailyCapacity.uploadDescription' }),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      renderCell: param => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const rowIndex = param.api.getRowIndexRelativeToVisibleRows(param.id);
        if ((param.id as string).endsWith('.xlsx')) {
          return (
            <Button
              sx={{ borderRadius: '16px' }}
              startIcon={<DeleteOutlineOutlinedIcon />}
              component="label"
              variant="outlined"
              onClick={() => {
                // Show confirmation dialog before allowing the file upload
                const userAccepted = window.confirm(
                  intl.formatMessage({ id: 'dailyCapacity.confirmUpload' }),
                );

                if (userAccepted) {
                  // If user accepts, trigger the file input dialog programmatically
                  const fileInput = document.createElement('input');
                  fileInput.type = 'file';
                  fileInput.accept = '.xlsx, .xls';

                  // Handle the file selection
                  fileInput.onchange = (e: Event) => {
                    // First, cast `e` to `unknown`, then cast it to `ChangeEvent<HTMLInputElement>`
                    const event = e as unknown as ChangeEvent<HTMLInputElement>;
                    const inputElement = event.target;

                    if (inputElement && inputElement.files) {
                      // Now you can safely call `handleFileUpload` with the correct event type
                      handleFileUpload(event, param.row);
                    }
                  };

                  // Trigger file input dialog
                  fileInput.click();
                } else {
                  alert(intl.formatMessage({ id: 'dailyCapacity.confirmfailed' }));
                }
              }}
            >
              <FormattedMessage id="popin.uploadFile" />
            </Button>
          );
        }
        return null;
      },
    },
  ];

  const id = datePickerOpen ? 'simple-popover' : undefined;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
    setDatePickerOpen(false);
  };

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Popover
        id={id}
        open={datePickerOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DatePicker
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
            field: {
              clearable: true,
              onClear: () => {
                setSelectedDate(undefined);
              },
            },
          }}
          format="dd/MM/yyyy"
          value={new Date(selectedDate ?? '')}
          onChange={(newDate: Date | null) => {
            setSelectedDate(newDate?.toISOString());
            setDatePickerOpen(false);
          }}
        />
      </Popover>

      <StripedDataGrid
        disableColumnMenu
        disableColumnFilter
        disableRowSelectionOnClick
        slotProps={{
          panel: {
            sx: {
              top: '-60px !important',
              minWidth: '650px !important',
            },
          },
        }}
        sx={{
          borderRadius: '16px',
        }}
        rows={dailyCapacities ?? []}
        filterModel={{
          items: [
            {
              field: COLUMN_LABEL_DAILY_CAPACITY.CREATION_DATE,
              operator: 'is',
              value: selectedDate,
            },
          ],
        }}
        columns={columns}
        sortModel={[
          {
            field: COLUMN_LABEL_DAILY_CAPACITY.CREATION_DATE,
            sort: 'desc',
          },
        ]}
        getRowId={row => row.path}
        getRowClassName={(params: any) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
      />
    </Box>
  );
};

export default DailyCapacityTable;
