/* eslint-disable @typescript-eslint/no-unused-vars */
import { SiteGateway } from '../../../corelogic/domain/gateways/site.gateway';
import { DailyCapacity } from '../../../corelogic/domain/models/dailyCapacity/dailyCapacity.model';
import { Gain } from '../../../corelogic/domain/models/gain/gain.model';
import { Site, SiteDeletion } from '../../../corelogic/domain/models/site/site.model';
import { adminSiteFixture } from './fixtures/adminSiteFixture';
import { createdSiteFixture } from './fixtures/createdSiteFixture';
import { gainFixture, gainsFixture } from './fixtures/gainFixture';
import { siteFixture } from './fixtures/siteFixture';
import { DailyCapacitiesFixture } from './fixtures/dailyCapacitiesFixture';

export class InMemorySiteGateway implements SiteGateway {
  private readonly site: Site = siteFixture;
  private readonly createdSite: Site = createdSiteFixture;
  private readonly adminRight: string[] = adminSiteFixture;
  private readonly gain: Gain = gainFixture;
  private readonly dailyCapacity: DailyCapacity[] = DailyCapacitiesFixture;
  private readonly url: string = 'P2M_Bayet_MLModel_PowerandHeatRate_01062023.xlsx';
  private readonly siteDeletion: SiteDeletion = { acknowledged: true, deletedCount: 1 };

  retrieveSite = async (): Promise<Site> => this.site;
  createSite = async (): Promise<Site> => this.createdSite;

  updateSiteCurve = async (): Promise<Site> => this.site;

  updateSiteParameter = async (): Promise<Site> => {
    return {
      ...this.site,
      name: 'newName',
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  retrieveMlFileFromS3 = async (fileName: string): Promise<string> => {
    return fileName;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  retrieveGain = async (siteId: string): Promise<Gain> => {
    return this.gain;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  retrieveDailyCapacities = async (siteId: string): Promise<DailyCapacity[]> => {
    return this.dailyCapacity;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  retrieveAdminRight = async (siteId: string): Promise<string[]> => {
    return this.adminRight;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateSiteModel = async (siteId: string, modelId: string, status: boolean): Promise<Site> => {
    return {
      ...this.site,
      mlModel: [
        {
          modelId: modelId,
          status: status,
          creationDate: '2023-03-31T23:34:56.000Z',
          name: 'new model',
          modelType: 'Power',
          effectiveDate: '2023-04-01T00:00:00.000Z',
        },
      ],
    };
  };

  updateSiteDailyCapacity = async (
    siteId: string,
    modifiedDailyCapacity: any[],
  ): Promise<string> => {
    return 'updated';
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deleteSite = async (siteId: string): Promise<SiteDeletion> => {
    return this.siteDeletion;
  };

  retrieveDailyCapacityFileFromS3 = async (
    fileName: string,
    siteId: string,
    type: string,
  ): Promise<string> => {
    return 'www.google.fr';
  };

  retrieveAllGains = async (): Promise<Gain[]> => {
    return gainsFixture;
  };
}
