import { Data, PlotData } from 'plotly.js';
import { useIntl } from 'react-intl';
import { KpiImbalance } from '../../../../../../corelogic/domain/models/kpiImbalance/kpiImbalance.model';
import { EfficiencyData } from '../../type';
import { ForecastInformationDTO } from '../../../../../../corelogic/domain/models/overview/forecastInformation.dto';

export const generalDashboardPlotlyDataBuilder = (
  isEfficiency: boolean | undefined,
  kpiImbalance: KpiImbalance[],
  hiddenCurveList: string[],
  efficiencyData?: EfficiencyData,
): Data[] => {
  const date: Date[] = [];
  const production_running: Array<number | null> = [];
  const net_power: Array<number | null> = [];
  const net_power_p2m_forecast: Array<number | null> = [];
  const net_power_p2m_estimate: Array<number | null> = [];
  const net_power_oem_estimate: Array<number | null> = [];
  const hhv_efficiency: Array<number | null> = [];
  const hhv_efficiency_p2m_estimate: Array<number | null> = [];
  const hhv_efficiency_p2m_estimate_min: Array<number | null> = [];
  const hhv_efficiency_oem_estimate: Array<number | null> = [];
  const hhv_efficiency_min_oem_estimate: Array<number | null> = [];
  const hhv_efficiency_p2m_forecast: Array<number | null> = [];
  const hhv_efficiency_min_p2m_forecast: Array<number | null> = [];

  kpiImbalance.forEach((kpi: KpiImbalance) => {
    date.push(new Date(kpi.timestamp));
    if (!isEfficiency) {
      production_running.push(Number(kpi.power_production_running_program?.toFixed(2)));
      net_power.push(Number(kpi.net_power?.toFixed(2)));
      net_power_p2m_forecast.push(Number(kpi.net_power_p2m_forecast?.toFixed(2)));
      net_power_p2m_estimate.push(Number(kpi.net_power_p2m_estimate?.toFixed(2)));
      net_power_oem_estimate.push(Number(kpi.net_power_oem_estimate?.toFixed(2)));
    } else {
      hhv_efficiency.push(Number(kpi.hhv_efficiency?.toFixed(2)));
      production_running.push(Number(kpi.hhv_efficiency_running_program?.toFixed(2)));
      hhv_efficiency_p2m_estimate.push(Number(kpi.hhv_efficiency_p2m_estimate?.toFixed(2)));
      hhv_efficiency_p2m_estimate_min.push(Number(kpi.hhv_efficiency_min_p2m_estimate?.toFixed(2)));
      hhv_efficiency_oem_estimate.push(Number(kpi.hhv_efficiency_oem_estimate?.toFixed(2)));
      hhv_efficiency_min_oem_estimate.push(Number(kpi.hhv_efficiency_min_oem_estimate?.toFixed(2)));
      hhv_efficiency_p2m_forecast.push(Number(kpi.hhv_efficiency_p2m_forecast?.toFixed(2)));
      hhv_efficiency_min_p2m_forecast.push(Number(kpi.hhv_efficiency_min_p2m_forecast?.toFixed(2)));
    }
  });

  const data: Partial<PlotData>[] = [];
  const intl = useIntl();
  data.push({
    x: date,
    y: production_running,
    name: intl.formatMessage({ id: 'generalDashboard.runningProgram' }),
    type: 'scatter',
    mode: 'lines',
    marker: { color: '#FF5722' },
    hovertemplate: 'y=%{y}<br>',
    visible: hiddenCurveList.includes(intl.formatMessage({ id: 'generalDashboard.runningProgram' }))
      ? 'legendonly'
      : undefined,
  });
  if (!isEfficiency) {
    data.push({
      x: date,
      y: net_power,
      name: intl.formatMessage({ id: 'generalDashboard.netActivePower' }),
      type: 'scatter',
      mode: 'lines',
      marker: { color: '#2196F3' },
      hovertemplate: 'y=%{y}<br>',
      visible: hiddenCurveList.includes(
        intl.formatMessage({ id: 'generalDashboard.netActivePower' }),
      )
        ? 'legendonly'
        : undefined,
    });
    data.push({
      x: date,
      y: net_power_p2m_forecast,
      name: intl.formatMessage({ id: 'generalDashboard.pmaxModelForecast' }),
      type: 'scatter',
      mode: 'lines',
      marker: { color: '#E91E63' },
      hovertemplate: 'y=%{y}<br>',
      visible: hiddenCurveList.includes(
        intl.formatMessage({ id: 'generalDashboard.pmaxModelForecast' }),
      )
        ? 'legendonly'
        : undefined,
    });
    data.push({
      x: date,
      y: net_power_p2m_estimate,
      name: intl.formatMessage({ id: 'generalDashboard.pmaxModel' }),
      type: 'scatter',
      mode: 'lines',
      marker: { color: '#9C27B0' },
      hovertemplate: 'y=%{y}<br>',
      visible: hiddenCurveList.includes(intl.formatMessage({ id: 'generalDashboard.pmaxModel' }))
        ? 'legendonly'
        : 'legendonly',
    });
    data.push({
      x: date,
      y: net_power_oem_estimate,
      name: intl.formatMessage({ id: 'generalDashboard.pmaxOemCurves' }),
      type: 'scatter',
      mode: 'lines',
      marker: { color: '#40A900' },
      hovertemplate: 'y=%{y}<br>',
      visible: hiddenCurveList.includes(
        intl.formatMessage({ id: 'generalDashboard.pmaxOemCurves' }),
      )
        ? 'legendonly'
        : undefined,
    });
  } else if (efficiencyData !== undefined) {
    data.push({
      x: date,
      y: hhv_efficiency,
      name: intl.formatMessage({ id: 'generalDashboard.hhvEfficiency' }),
      type: 'scatter',
      mode: 'lines',
      marker: { color: '#2196F3' },
      hovertemplate: 'y=%{y}<br>',
      visible: hiddenCurveList.includes(
        intl.formatMessage({ id: 'generalDashboard.hhvEfficiency' }),
      )
        ? 'legendonly'
        : undefined,
    });
    data.push({
      x: date,
      y: efficiencyData.min ? hhv_efficiency_min_p2m_forecast : hhv_efficiency_p2m_forecast,
      name: efficiencyData.hhv_efficiency_p2m_forecast_curveName,
      type: 'scatter',
      mode: 'lines',
      marker: { color: '#E91E63' },
      hovertemplate: 'y=%{y}<br>',
      visible: hiddenCurveList.includes(efficiencyData.hhv_efficiency_p2m_forecast_curveName)
        ? 'legendonly'
        : undefined,
    });

    data.push({
      x: date,
      y: efficiencyData.min ? hhv_efficiency_p2m_estimate_min : hhv_efficiency_p2m_estimate,
      name: efficiencyData.hhv_efficiency_p2m_estimate_curveName,
      type: 'scatter',
      mode: 'lines',
      marker: { color: '#9C27B0' },
      hovertemplate: 'y=%{y}<br>',
      visible: hiddenCurveList.includes(efficiencyData.hhv_efficiency_p2m_estimate_curveName)
        ? 'legendonly'
        : undefined,
    });
    data.push({
      x: date,
      y: efficiencyData.min ? hhv_efficiency_min_oem_estimate : hhv_efficiency_oem_estimate,
      name: efficiencyData.hhv_efficiency_oem_estimate_curveName,
      type: 'scatter',
      mode: 'lines',
      marker: { color: '#547A29' },
      hovertemplate: 'y=%{y}<br>',
      visible: hiddenCurveList.includes(efficiencyData.hhv_efficiency_oem_estimate_curveName)
        ? 'legendonly'
        : undefined,
    });

    data.push({
      x: date,
      y: efficiencyData.min ? hhv_efficiency_p2m_estimate : hhv_efficiency_p2m_estimate_min,
      name: efficiencyData.hhv_efficiency_min_p2m_estimate_curveName,
      type: 'scatter',
      mode: 'lines',
      marker: { color: '#B39DDB' },
      hovertemplate: 'y=%{y}<br>',
      visible: hiddenCurveList.includes(efficiencyData?.hhv_efficiency_min_p2m_estimate_curveName)
        ? 'legendonly'
        : undefined,
    });
  }
  return data;
};

export const dispatchDashboardPlotlyDataBuilder = (
  kpiImbalance: KpiImbalance[],
  forecastImbalance: ForecastInformationDTO[],
  hiddenCurveList: string[],
): Data[] => {
  const data: Partial<PlotData>[] = [];
  const intl = useIntl();
  const date: Date[] = [];
  const dateForecast: Date[] = [];

  const net_power: Array<number | null> = [];
  const net_power_p2m_forecast: Array<number | null> = [];
  const net_power_p2m_estimate: Array<number | null> = [];
  const net_power_oem_estimate: Array<number | null> = [];
  const production_running: Array<number | null> = [];

  const p2m_model_forecast: Array<number | null> = [];
  const running_program: Array<number | null> = [];

  kpiImbalance.forEach((kpi: KpiImbalance) => {
    date.push(new Date(kpi.timestamp));
    net_power.push(Number(kpi.net_power?.toFixed(2)));
    net_power_p2m_forecast.push(Number(kpi.net_power_p2m_forecast?.toFixed(2)));
    net_power_p2m_estimate.push(Number(kpi.net_power_p2m_estimate?.toFixed(2)));
    net_power_oem_estimate.push(Number(kpi.net_power_oem_estimate?.toFixed(2)));
    production_running.push(Number(kpi.power_production_running_program?.toFixed(2)));
  });

  forecastImbalance.forEach((forecast: ForecastInformationDTO) => {
    dateForecast.push(new Date(forecast.timestamp));
    p2m_model_forecast.push(Number(forecast.p2m_model_forecast?.toFixed(2)));
    running_program.push(Number(forecast.running_program?.toFixed(2)));
  });
  data.push({
    x: date,
    y: production_running,
    name: intl.formatMessage({ id: 'generalDashboard.runningProgram' }),
    type: 'scatter',
    mode: 'lines',
    marker: { color: '#FF5722' },
    hovertemplate: 'y=%{y}<br>',
  });

  data.push({
    x: date,
    y: net_power,
    name: intl.formatMessage({ id: 'generalDashboard.netActivePower' }),
    type: 'scatter',
    mode: 'lines',
    marker: { color: '#2196F3' },
    hovertemplate: 'y=%{y}<br>',
  });

  data.push({
    x: date,
    y: net_power_p2m_forecast,
    name: intl.formatMessage({ id: 'generalDashboard.pmaxModelForecast' }),
    type: 'scatter',
    mode: 'lines',
    marker: { color: '#E91E63' },
    hovertemplate: 'y=%{y}<br>',
  });

  data.push({
    x: date,
    y: net_power_p2m_estimate,
    name: intl.formatMessage({ id: 'generalDashboard.pmaxModel' }),
    type: 'scatter',
    mode: 'lines',
    marker: { color: '#9C27B0' },
    hovertemplate: 'y=%{y}<br>',
    visible: 'legendonly',
  });
  data.push({
    x: date,
    y: net_power_oem_estimate,
    name: intl.formatMessage({ id: 'generalDashboard.pmaxOemCurves' }),
    type: 'scatter',
    mode: 'lines',
    marker: { color: '#40A900' },
    hovertemplate: 'y=%{y}<br>',
  });

  data.push({
    x: dateForecast,
    y: p2m_model_forecast,
    name: intl.formatMessage({ id: 'generalDashboard.pmaxModelForecast' }),
    type: 'scatter',
    mode: 'lines',
    line: { dash: 'dot', width: 2 },
    marker: { color: '#E91E63' },
    hovertemplate: 'y=%{y}<br>',
    visible: hiddenCurveList.includes(
      intl.formatMessage({ id: 'generalDashboard.pmaxModelForecast' }),
    )
      ? 'legendonly'
      : undefined,
  });

  data.push({
    x: dateForecast,
    y: running_program,
    name: intl.formatMessage({ id: 'generalDashboard.runningProgram' }),
    type: 'scatter',
    mode: 'lines',
    line: { dash: 'dot', width: 2 },
    marker: { color: '#FF5722' },
    hovertemplate: 'y=%{y}<br>',
    visible: hiddenCurveList.includes(intl.formatMessage({ id: 'generalDashboard.runningProgram' }))
      ? 'legendonly'
      : undefined,
  });
  return data;
};
