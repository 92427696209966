import { PayloadAction } from '@reduxjs/toolkit';
import { ofType, StateObservable } from 'redux-observable';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';
import { Dependencies } from '../../../../../configurations/dependencies.interface';
import { RootState } from '../../../../redux/create-store';
import {
  setUpdateSiteDailyCapacityFailure,
  setUpdateSiteDailyCapacitySuccess,
} from '../../../../redux/site/site.slice';

export const updateSiteDailyCapacityEpic = (
  action$: Observable<
    PayloadAction<{ siteId: string; modifiedDailyCapacity: FormData; creationDate: string }>
  >,
  _state$: StateObservable<RootState>,
  dependencies: Dependencies,
) =>
  action$.pipe(
    ofType('site/setUpdateSiteDailyCapacity'),
    switchMap(action => {
      return from(
        dependencies.siteGateway.updateSiteDailyCapacity(
          action.payload.siteId,
          action.payload.modifiedDailyCapacity,
          action.payload.creationDate,
        ),
      ).pipe(
        map(() => setUpdateSiteDailyCapacitySuccess()),
        catchError(() =>
          of(
            setUpdateSiteDailyCapacityFailure(
              `update daily Capacity ${action.payload.siteId} has failed`,
            ),
          ),
        ),
      );
    }),
  );
